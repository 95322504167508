@import url('https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap');


.contentContainer{
    display: flex;
    margin: 0 5vw;
}
.imageContainer{
    flex:30%;
    
}
.textContainer
{
    flex:70%;
    font-family: 'Noto Serif', cursive;
    font-size: 25px;
    overflow: none;
    text-align: justify;
    align-self: center;

}
.reveal{
    position: relative;
    transform: translateY(150px);
    opacity: 0;
    transition: 2s all ease;
  }
  .reveal.active{
    transform: translateY(0);
    opacity: 1;
  }

  @media (max-width: 767px){
    .contentContainer{
        flex-direction: column;
    }
    .imageContainer{
        flex:auto;
        
    }
    .textContainer
    {
        font-size: 10px;
    }
    .textImage{
        max-width: 90%;
    }
  }