html {
  scroll-behavior:smooth;
  overflow-x: hidden;
}

.App {
  text-align: center;
}

body{
    margin: 0;
    padding: 0;
    background-color:#8c90b6;
}

.base2{
  background-image: url('./media/stairway.png');

}