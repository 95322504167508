

.review-container {
    width: 100vw;
    height: auto;
    overflow: hidden;
    
  }
  
  .review-items {
    width: 100%; /* 3 review items, each with 600px width */
    display: flex;
    flex-direction: column;
  }
  
  .review-item {
    width: 30%;
    float: left;
    padding: 20px;
    margin:10px;
    box-sizing: border-box;
    font-size: 16px;
    color:white;
    font-size: 1.4rem;
    border: 2px solid black;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    text-align: justify;
  }
  
  .review-rating {
    margin: 10px 0;
  }
  
  .review-author {
    font-style: italic;
    text-align: right;
  }

  @media (max-width: 767px){
    .review-item{
      width: 95%;
      padding: 2%;

    }
    .review-author {
      font-size: 1.2rem;
    }
  }