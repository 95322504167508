@import url('https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap');

.courseChoiceContainer{
    width: 95vw;
    height: 100vh;
    margin: 5px auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: auto

}

.optionCon {
    flex-basis:30%;
    height: 80%;
    box-shadow: inset 0 1px 3px rgb(238, 234, 234),0 1px 0 rgba(253, 1, 1, 0.897);
    border: 1px solid #ccc;
    margin: 10px;
    background: rgba(255, 255, 255, 0.09);
    overflow: hidden;
    animation:flick 0.5s;
    cursor: pointer;
    user-select: none;
}

.optionCon > h1{
    margin: 0;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 3em;
}
.toggleDisplay{
    transition: opacity 200ms, display 200ms;
    display:none;
    opacity: 0;
}
.textContainer{
    text-align: center;
    width: 100%;
    height: 100%;
    font-family: 'Noto Serif';
    overflow-y:none;
}

.courseText{
    white-space: pre-wrap;
}

.courseTitle{
    color:white
}
.backBtn{
    width: 40%;
    margin:10px;
    border-radius: 10px;
    font-size: 25px;
    background-color: white;
    flex-wrap:wrap;
    font-family: 'Noto Serif';
}

.backBtn:hover{
    background-color: black;
    color:white;
    cursor:pointer;
}
.googleForm{
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -45%);
        height: auto;
        z-index: 10;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        background-color: rgba(50,50,50,0.8);
        border: 1px solid black;
        padding: 20px;
        overflow: auto;

}
.googleForm > iframe {
    align-self: center;
}

.closeApplication{
    align-self:center;
    border-radius:10px;
    margin-bottom: 10px;
    padding:10px;
}

.closeApplication:hover{
    background-color: black;
    color: white;
    cursor: pointer;
}

@keyframes flick {
    0%{
        transform: rotate(0deg);
    }
    
    33%{
        transform:rotate(-5deg);
    }

    66%{
        transform:rotate(5deg);
    }
    100%{
        transform: rotate(0deg);
    }

}

@media (max-width: 767px){
    .courseChoiceContainer{
        flex-direction: column;
        height: auto;
    }
    .optionCon {
        height: auto;
    }
    .optionCon > h1{
        transform: translate(-50%, 0%);
        line-height: 2em;
        padding: 10px 0;
    }
    .googleForm > iframe {
        max-width: 90%;
    }
    .courseTitle{
        font-size: 3rem;
    }
}