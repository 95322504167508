.datasec-cont{
    text-align: left;
    margin:10px;
}
.datasec-cont h1{
    color: black;
}
.datasec-cont p{
    font-size: 1rem;
}
.datasec-cont a{
    font-size: 1rem;
}

.backButton{
    border: 2px solid black;
    border-radius: 10px;
    margin:20px;
}