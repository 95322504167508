.footer{
    width: 100vw;
    height:10vh;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.3);
    border-top: 2px solid black;
    padding: 30px;
}

.footer > p {
    font-size:1.2rem;
}

@media (max-width: 767px){
    .footer{
        padding:0;
        height: auto;
    }
    .footer > p{
        font-size:1rem;
    }
}