@import url('https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap');

.header_base{
    position:fixed;
    top:0px;
    width: 100vw;
    height:100px;
    display: flex;
    background-color:#8c90b6;
    z-index: 2;
}

.logo{
    width: 33.3%;
    height: 100%;
    display: flex;
}
.logoimg{
    height: 100%;
    align-self: flex-start;
    margin-left:40px;
}

.navButtons{
    width:66.6%;
    text-align: end;
    margin-right: 50px;
    margin-top: 30px;
}

a{
    padding: 10px;
    text-decoration: none;
    color:white;
    font-family: 'Patrick Hand', cursive;
    font-size: 23px;

}

a:hover{
    color:red;
}

@media (max-width: 767px){
    .navButtons{
        width:100%;
        text-align: center;
        margin:0;
    }
    a{
        padding: 5px;
        font-size: 20px;
    
    }
    .header_base{
        height:auto;
    }
    .logoimg{
        height: 50%;
        align-self: flex-start;
        margin-left:40px;
    }
    .navButtons{
        margin:0;
    }
    .logo{
        display: none;
    }
}

