@import url('https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap');

.bioContainer{
    display: flex;
    flex-flow: row;
    width:90vw;
    margin: 30px auto;
    text-align: justify;
    font-family: 'Noto Serif', cursive;
    hyphens: auto;
}

.profilePic{
    height:20em;
    width: 20em;
    align-self: center;
    border-radius: 50%;
    border: 2px solid black;
    margin-left: 5em;
}

h1{
    text-align: center;
}

p{
    font-size: 30px;
}

.reveal2{
    position: relative;
    transform: translateY(120px);
    opacity: 0;
    transition: 2s all ease;
  }
  .reveal2.active{
    transform: translateY(0);
    opacity: 1;
  }

  @media (max-width: 767px){
    .contentContainer{
        flex-direction: column;
    }
    .imageContainer{
        flex:auto;
        
    }
    .textContainer
    {
        font-size: 10px;
    }
    .textImage{
        max-width: 90%;
    }
  }
@media (max-width: 767px){
    .bioContainer{
        flex-flow: column;
    }
    .profilePic{
        margin-left: 0;
    }

}