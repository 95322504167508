@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif&display=swap');


.background_container{
    width:100vw;
    max-width:100%;
    padding: 0;
    margin: 0;
    border: 0;
    margin-top: 0px;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    
    
}
.image_container{
     width: 100%;
    position: static;
    height: 100%;

}
.background_photo{
    flex-basis: fit-content;
    filter:blur(5px);
}

.quote {
    animation: slide-in 5s ease-out;
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    animation-fill-mode: forwards;  
    font-family: 'Dancing Script', cursive;
    text-shadow: -0.5px 0 rgba(255, 255, 255, 0.377), 0 0.5px rgba(255, 255, 255, 0.377), 1px 0 rgba(255, 255, 255, 0.377), 0 -1px rgba(255, 255, 255, 0.377);

  }
  .quote h1{
    text-align: left;
    margin-right: 5vw;
    color:black;
  }
  .quote p {
    color:black;
    font-size: 2rem;
  }
  .next_course::before {
    content: '';
    background: #301c1a;
    position: absolute;
    width: 110%;
    height: 110%;
    left: 0;
    top: 0;
    z-index: 1;
    animation: lr-bar 750ms;
    animation-fill-mode: both;
    animation-timing-function: cubic-bezier(0.500, 0.250, 0.500, 0.750);
  }
  .next_course{
    position: absolute;
    top:10%;
    left: 5%;
    text-align: left;
    font-family: 'Noto Serif', sans-serif;

  }

  

  .next_course_question{
    font-weight: 700;
    font-size: 48px;
    color:black;
  }

  .next_course_time{
    font-family: 'Noto Serif';
    color:black;
  }
  .course_link_cont{
    animation: pulse 10s infinite;
    
  }
  .course_link{
    background-color:rgba(0, 0, 255, 0.075);
    border-radius: 5px;
    font-size: 40px;
    color:red;
    margin: 20px;
    border:1px solid black;
    
    
  }
  .course_link:hover{
    color:white;
  }
  .name{
    text-align: right;
  }

  h1,p{
    color:rgba(255, 255, 255, 0.76);
  }
@keyframes slide-in {
    from {
      transform: translateX(-50%);
    }
    to {
      transform: translateX(0);
    }
  }


  @keyframes pulse {
    0%{
      transform:scale(100%);
    }
    50%{
      transform:scale(105%);
    }
    100%{
      transform:scale(100%);
    }
  }
  
  @keyframes lr-bar {
    0% { transform-origin: center left 0px; transform: scaleX(0); }
    36% { transform: scaleX(1); transform-origin: center left 0px; }
    54% { transform: scaleX(1); transform-origin: center right 0px; }
    90% { transform: scaleX(0);  transform-origin: center right 0px; }
    100% { transform: scaleX(0);  transform-origin: center right 0px; }
  }

  @media (max-width: 767px){
    .quote{
      font-size: 8px;
      left:30%;
      top:80%;
    }
    .next_course_question{
      font-weight: 200;
      font-size:30px;
    }
    .next_course{
      top:20%;
      text-align: center;
    }
    .background_container{
      background-attachment: scroll;
      margin-top: 0px;
      height: 100vh;
      width:100vw;
      max-width:100%;
      background-image: url(./media/mobilebackg.jpg);
    }
    .course_link{
      background-color:rgba(255, 255, 255, 0.267);
    }
  }

  @media only screen 
    and (device-width: 375px) 
    and (device-height: 812px) 
    and (-webkit-device-pixel-ratio: 3) {
      .image_container{
        position: relative;
     }
     .background_container{
      background-attachment: scroll;
      padding: 0;
      margin: 0;
      border: 0;
      margin-top: 0px;
      background-size: cover;
      background-attachment: scroll;
      background-position: center;
      background-repeat: no-repeat;
      }
    }
 @media only screen 
    and (device-width: 390px) 
    and (device-height: 844px) 
    and (-webkit-device-pixel-ratio: 3) { 
      .image_container{
        position: relative;
     }
     .background_container{
      background-attachment: scroll;
      padding: 0;
      margin: 0;
      border: 0;
      margin-top: 0px;
      background-size: cover;
      background-attachment: scroll;
      background-position: center;
      background-repeat: no-repeat;
      }
    }
    
    /* 2778x1284 pixels at 458ppi */
@media only screen 
    and (device-width: 428px) 
    and (device-height: 926px) 
    and (-webkit-device-pixel-ratio: 3) {
      .image_container{
        position: relative;
     }
     .background_container{
      background-attachment: scroll;
      padding: 0;
      margin: 0;
      border: 0;
      margin-top: 0px;
      background-size: cover;
      background-attachment: scroll;
      background-position: center;
      background-repeat: no-repeat;
     }
     }
/* 1792x828px at 326ppi */
@media only screen 
    and (device-width: 414px) 
    and (device-height: 896px) 
    and (-webkit-device-pixel-ratio: 2) {
      .image_container{
        background-attachment: scroll;
     }
     .background_container{
      background-attachment: scroll;
      padding: 0;
      margin: 0;
      border: 0;
      margin-top: 0px;
      background-size: cover;
      background-attachment: scroll;
      background-position: center;
      background-repeat: no-repeat;
     }
     }
@media only screen 
    and (device-width: 375px) 
    and (device-height: 812px) 
    and (-webkit-device-pixel-ratio: 3) {
      .image_container{
        position: relative;
     }
     .background_container{
      background-attachment: scroll;
      padding: 0;
      margin: 0;
      border: 0;
      margin-top: 0px;
      background-size: cover;
      background-attachment: scroll;
      background-position: center;
      background-repeat: no-repeat;
     }
     }
@media only screen 
    and (device-width: 414px) 
    and (device-height: 896px) 
    and (-webkit-device-pixel-ratio: 3) { 
      .image_container{
        position: relative;
     }
     .background_container{
      background-attachment: scroll;
      padding: 0;
      margin: 0;
      border: 0;
      margin-top: 0px;
      background-size: cover;
      background-attachment: scroll;
      background-position: center;
      background-repeat: no-repeat;
     }
    }
 @media only screen 
    and (device-width: 375px) 
    and (device-height: 667px) 
    and (-webkit-device-pixel-ratio: 2) { 
      .image_container{
        position: relative;
     }
     .background_container{
      background-attachment: scroll;
      padding: 0;
      margin: 0;
      border: 0;
      margin-top: 0px;
      background-size: cover;
      background-attachment: scroll;
      background-position: center;
      background-repeat: no-repeat;
      }
    }