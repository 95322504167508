.form-container {
    max-width: 500px;
    margin: 0 auto;
    padding: 30px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  
  .form-container h2 {
    text-align: center;
    margin-bottom: 30px;
    color: #333;
  }

  
.form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 10px;
    font-weight: 500;
    color: #555;
  }
  
  .form-group input,
  .form-group select,
  .form-group textarea {
    width: 90%;
    margin: 0 auto;
    padding: 12px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #fff;
    font-size: 16px;
    color: #333;
    box-shadow: none;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }

  .form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  border-color: #666;
  outline: none;
}

.form-group select option {
  color: #333;
}

.form-group textarea {
  height: 120px;
}

.btn {
  display: inline-block;
  padding: 12px 20px;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  transition: background-color ease-in-out 0.15s, color ease-in-out 0.15s;
  cursor: pointer;
}

.btn:hover {
  background-color: #0062cc;
  color: #fff;
}

@media (max-width: 768px) {
  .form-container {
    width: 75%;
    margin:0 auto;
  }
  .form-group{
    margin: 0 auto;
    width: 90%;
    padding: 0;
  }
  .form-group label{
    font-size: 1.2rem;
  }
  .form-group input,
  .form-group select,
  .form-group textarea {
    font-size: 1rem;
  }

  .btn {
    font-size: 14px;
  }
}